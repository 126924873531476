import React from 'react';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Unit from '../../../templates/olea/unit';

function UnitPage({ data }) {
  const unit = data?.units?.edges?.[0]?.node?.data;
  return <Unit data={{
    unit: {
      data: unit,
    },
  }} />;
}

UnitPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    units: allPrismicGenericUnit(
      limit: 1
      filter: {data: {property_selection: {eq: "Olea"}}}
    ) {
        edges {
            node {
                data {
                    apt_number
                    apt_type
                    available_at
                    available_now
                    description {
                        text
                    }
                    floor
                    price
                    application_url {
                        url
                    }
                    banner_images {
                        image {
                            url
                        }
                    }
                    grid {
                        url
                    }
                    apt_layout {
                        url
                    }
                    virtual_tour_url {
                        url
                    }
                }
            }
        }
    }
  }
`;

export default withPrismicPreview(UnitPage);
